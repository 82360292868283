import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { useTranslation } from 'react-i18next';
import { Body } from '@components/styled/Typography';
import getColors from './styles';
const Container = styled('div', { shouldForwardProp: propName => isPropValid(propName) })((props) => (Object.assign({ position: 'absolute', top: '-40px', display: 'flex', alignItems: 'center', height: '40px', backgroundColor: props.colorValue, borderRadius: '12px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, boxSizing: 'border-box', padding: '10px 20px', ':after': {
        position: 'absolute',
        right: '-18px',
        bottom: 0,
        content: '""',
        width: 0,
        height: 0,
        border: '1px solid',
        borderColor: `transparent transparent ${props.colorValue} transparent`,
        borderWidth: '0 20px 35px 0',
    }, ':before': {
        position: 'absolute',
        left: 0,
        bottom: '-10px',
        content: '""',
        width: '10px',
        height: '10px',
        backgroundColor: props.colorValue,
    } }, (props.customStyles || {}))));
const PortfolioBadge = ({ portfolio, viewType, isHovered = false, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const variant = useMemo(() => {
        if (viewType === 'preview-create') {
            return 'preview';
        }
        if (portfolio.is_archived) {
            return 'archive';
        }
        if (!portfolio.assessmentPassed) {
            return 'info';
        }
        if (portfolio.has_error) {
            return 'error';
        }
        return undefined;
    }, [portfolio, viewType]);
    const colors = useMemo(() => getColors(theme).byVariants[variant !== null && variant !== void 0 ? variant : 'info'], [theme, variant]);
    const content = useMemo(() => {
        let result;
        switch (variant) {
            case 'info':
                result = (_jsx(Body, { children: `${t('portfolio.assessment_review')}: 3 ${t('periods.day', { ns: 'common', count: 3 })}` }));
                break;
            case 'archive':
                result = (_jsx(Body, { children: t('portfolio.history') }));
                break;
            case 'error':
                result = (_jsx(Body, { children: `3 ${t('periods.day', { ns: 'common', count: 3 })} 15:09:31` }));
                break;
            case 'preview':
                result = (_jsx(Body, { children: t('portfolio.create_portfolio.steps.portfolio_settings.preview_label', { ns: 'common' }) }));
                break;
            default:
        }
        return result;
    }, [variant, t]);
    if (!variant) {
        return null;
    }
    return (_jsx(Container, { colorValue: isHovered ?
            colors.backgroundColorOnHover :
            colors.backgroundColor, style: {
            color: isHovered ?
                colors.textColorOnHover :
                colors.textColor,
        }, customStyles: Object.assign({}, variant === 'preview' && ({
            height: 30,
            top: '-30px',
            padding: '5px 20px',
            '&:after': {
                borderWidth: '0 20px 25px 0',
            },
        })), children: content }));
};
export default PortfolioBadge;

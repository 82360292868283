import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { formatPercent, formatNumber, formatUSDT, } from '@shared/lib/numbers';
import { ReactComponent as ViewsIcon } from '@icons/wolfkit-light/eye-light.svg';
import { Body } from '@components/styled/Typography';
import { ContainerRow } from '@components/styled';
import Icon from '@shared/ui/icons/Icon';
const Container = styled(ContainerRow)((props) => ({
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `8px ${props.viewType === 'card' ? 24 : 48}px`,
    color: props.theme.customColors.portfolio.onHover,
    backgroundColor: props.theme.customColors.portfolio.hover,
    borderRadius: '0 0 12px 12px',
    outline: `2px solid ${props.theme.customColors.portfolio.hover}`,
    outlineOffset: '-2px',
}));
const ViewsContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const Text = styled(Body)(() => ({
    lineHeight: '18px',
}));
const OverlayContent = ({ className = undefined, portfolio, viewType, }) => {
    var _a;
    const { t } = useTranslation();
    const theme = useTheme();
    const displayFullContent = viewType === 'list' || viewType === 'edit';
    return (_jsxs(Container, { className: className, viewType: viewType, children: [_jsxs(ViewsContainer, { children: [_jsx(Icon, { color: theme.customColors.button.onPrimary, IconComponent: ViewsIcon }), _jsx(Text, { children: formatNumber(portfolio.viewed, {
                            compactNotation: true,
                            maximumFractionDigits: 0,
                        }) })] }), _jsx(Text, { children: `${t('portfolio.min_balance')}: ${formatUSDT(portfolio.min_balance, { compactNotation: true })}` }), displayFullContent &&
                (_jsxs(_Fragment, { children: [_jsx(Text, { children: `$ PLACEHOLDER: ${(_a = portfolio.exchange) === null || _a === void 0 ? void 0 : _a.id}` }), _jsx(Text, { children: `${formatNumber(portfolio.whole_period_days_count, { compactNotation: true, maximumFractionDigits: 0 })} ${t('portfolio.days_running')}` }), _jsx(Text, { children: `${formatPercent(portfolio.past_week_difference)} ${t('portfolio.past_week')}` }), _jsx(Text, { children: `${formatPercent(portfolio.past_ninety_days_difference)} ${t('portfolio.past_ninety_days')}` })] }))] }));
};
export default OverlayContent;
